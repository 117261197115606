import React, { Fragment } from 'react'
import { graphql, Link } from 'gatsby'

import { TitleAndMetaTags } from '../components/Helpers.TitleAndMetaTags'
import { Layout } from '../components/Layout'
import { SmallWrapper } from '../components/Layout.Wrapper'
import { Spacer } from '../components/Layout.Spacer'
import { HugeHeading, H3 } from '../components/Typography.Headings'
import { Button } from '../components/Common.Button'


export const ThanksPageContent = ({ data }) => {

  const { config } = data
  const { thanksTitle, thanksText } = config
  
  return (
    <Fragment>
      <Spacer height={25}/>

      <SmallWrapper alignment="center"> 
        <HugeHeading color="purple">{thanksTitle}</HugeHeading>
        <H3 color="purple">{thanksText}</H3>
        <Link to={'/'}>
          <Button>Go to Popcry.pt</Button>
        </Link>
      </SmallWrapper>

    </Fragment>
  )
}

export default function ThanksPage({ data, location }) {
  return (
    <Layout location={location}>
      <TitleAndMetaTags />
      <ThanksPageContent data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query thanksQuery {
    markdownRemark(fields: { id: { eq: "thanks" } }) {
      ...PageMetaFragmentMarkdown
    }
    config: configYaml {
      thanksTitle
      thanksText
    }
  }
`

